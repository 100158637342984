import React, { useEffect, useState } from 'react'
import { InputContainer, InputFormContainer, SummaryFertTable, SummaryFilterOptionsContainer, SummaryGraphContainer, SummaryStatContainer, SummaryStatRow } from '../styles/Container.styled'
import { InputFormHeading, InputMainHeadingContainer, Select } from '../styles/Input.styled'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { SummaryGraphTitle, SummaryStat, SummaryStatDescription } from '../styles/Label.styled'
import { clearErrors, getDetailSummary, getInitialSummary } from '../../features/footprint/footprintSlice'
import { toast } from 'react-toastify'
import Chart from "react-apexcharts";
import Loader from '../layout/Loader';
import { AdminSummaryFilterButton, AdminSummaryPaginationButton } from '../styles/Button.styled'
import { getDropdownValues } from '../../features/operations/operationsSlice'
var pjson = require('../../../package.json');

type Props = {}

const Summary = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [region, setRegion] = useState("");
    const [system, setSystem] = useState("");
    const [startYear, setStartYear] = useState("");
    const [endYear, setEndYear] = useState("");
    const [minHorses, setMinHorses] = useState("");
    const [maxHorses, setMaxHorses] = useState("");
    const [minArea, setMinArea] = useState("");
    const [maxArea, setMaxArea] = useState("");
    const [assessmentType, setAssessmentType] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { loading, errors, adminInitialSummary, adminDetailSummary, detailSummaryLoading } = useAppSelector(state => state.footprint);
    const { loading: operartionsLoading, dropdowns } = useAppSelector(state => state.operations);

    useEffect(() => {
        dispatch(getDropdownValues({ section: "HoldingInformationInput" }))

        if (!pulledData) {
            dispatch(getInitialSummary());
            setPulledData(true);
        }

        if (adminDetailSummary?.partialDatasetSummaires.paginationMetaData.currentPage && (currentPage !== adminDetailSummary.partialDatasetSummaires.paginationMetaData.currentPage)) {
            toast.info("Filtering data...");
            dispatch(getDetailSummary({
                region,
                minimumTotalHoldingArea: Number(minArea),
                minimumNumberOfHorses: Number(minHorses),
                maximumTotalHoldingArea: Number(maxArea),
                maximumNumberOfHorses: Number(maxHorses),
                holdingSystem: system,
                startYear: Number(startYear),
                endYear: Number(endYear),
                pageNumber: currentPage
            }))
        }

        if (errors) {
            toast.error(errors["Message"])
            console.log(errors);
            dispatch(clearErrors())
        }

    }, [adminDetailSummary?.partialDatasetSummaires.paginationMetaData.currentPage, currentPage, dispatch, endYear, errors, maxArea, maxHorses, minArea, minHorses, pulledData, region, startYear, system])

    const distinctRegions = () => {
        const result = [];
        const map = new Map();
        for (const item of adminInitialSummary?.holdingSummary ?? []) {
            if (!map.has(item.region)) {
                map.set(item.region, true);    // set any value to Map
                result.push(item.region);
            }
        }
        return result
    };

    const holdingRegionsOptions = {
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: distinctRegions(),
        }
    }

    const generateHoldingRegionsSeries = () => {
        var regions = distinctRegions()
        var counts = new Array(regions.length).fill(0);
        adminInitialSummary?.holdingSummary?.map(element => element.region).forEach(element => {
            var index = regions.indexOf(element)
            if (index !== -1) counts[index] = counts[index] + 1
        })

        return [{
            name: "Holdings in this Region",
            data: counts
        }]
    }

    const distinctSystems = () => {
        const result = [];
        const map = new Map();
        for (const item of adminInitialSummary?.holdingSummary ?? []) {
            if (!map.has(item.systemDescription)) {
                map.set(item.systemDescription, true);    // set any value to Map
                result.push(item.systemDescription);
            }
        }
        return result
    };

    const holdingSystemsOptions = {
        labels: distinctSystems() as string[],
        chart: {
            // type: 'donut',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom'
                },

            }
        }],

    }

    const generateHoldingSystemsSeries = () => {
        var systems = distinctSystems()
        var counts = new Array(systems.length).fill(0);
        adminInitialSummary?.holdingSummary?.map(element => element.systemDescription).forEach(element => {
            var index = systems.indexOf(element)
            if (index !== -1) counts[index] = counts[index] + 1
        })

        return counts
    }

    const distinctAssessmentTypes = () => {
        const result = [];
        const map = new Map();
        for (const item of adminInitialSummary?.footPrintSummaryDtos ?? []) {
            if (!map.has(item.assessmentType)) {
                map.set(item.assessmentType, true);    // set any value to Map
                result.push(item.assessmentType);
            }
        }
        return result
    };

    const footprintAssessmentTypesOptions = {
        labels: distinctAssessmentTypes() as string[],
        chart: {
            // type: 'donut',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom'
                },

            }
        }],

    }

    const generateAssessmentTypesSeries = () => {
        var types = distinctAssessmentTypes()
        var counts = new Array(types.length).fill(0);
        adminInitialSummary?.footPrintSummaryDtos?.map(element => element.assessmentType).forEach(element => {
            var index = types.indexOf(element)
            if (index !== -1) counts[index] = counts[index] + 1
        })

        var result = counts.map((element, index) => {
            return { x: types[index] as string, y: element as number }
        })

        return [{
            data: result
        }]
    }

    const distinctAccountingEndYear = () => {
        const result = [];
        const map = new Map();
        for (const item of adminInitialSummary?.footPrintSummaryDtos ?? []) {
            if (!map.has(String(item.accountingPeriodEndYear))) {
                map.set(String(item.accountingPeriodEndYear), true);    // set any value to Map
                result.push(String(item.accountingPeriodEndYear));
            }
        }
        return result
    };

    const accountingPeriodEndOptions = {
        labels: distinctAccountingEndYear() as string[],
        chart: {
            // type: 'donut',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom'
                },

            }
        }],

    }

    const generateAccountingEndYearSeries = () => {
        var endYears = distinctAccountingEndYear()
        var counts = new Array(endYears.length).fill(0);
        adminInitialSummary?.footPrintSummaryDtos?.map(element => element.accountingPeriodEndYear).forEach(element => {
            var index = endYears.indexOf(String(element))
            if (index !== -1) counts[index] = counts[index] + 1
        })

        return counts
    }

    const distinctMuckMethods = () => {
        const result = [];
        const map = new Map();
        for (const item of adminInitialSummary?.footPrintSummaryDtos ?? []) {
            if (!map.has(item.manureManagement)) {
                map.set(item.manureManagement, true);    // set any value to Map
                result.push(item.manureManagement);
            }
        }
        return result
    };

    const muckMethodOptions = {
        labels: distinctMuckMethods() as string[],
        chart: {
            // type: 'donut',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom'
                },

            }
        }],

    }

    const generateMuckMethodSeries = () => {
        var methods = distinctMuckMethods()
        var counts = new Array(methods.length).fill(0);
        adminInitialSummary?.footPrintSummaryDtos?.map(element => element.manureManagement).forEach(element => {
            var index = methods.indexOf(element)
            if (index !== -1) counts[index] = counts[index] + 1
        })

        return counts
    }

    const distinctGrazingPattern = () => {
        const result: string[] = [];
        const map = new Map();
        for (const item of adminInitialSummary?.footPrintSummaryDtos ?? []) {
            item.grazingPattern?.forEach(element => {
                if (!map.has(element)) {
                    map.set(element, true);    // set any value to Map
                    result.push(element);
                }
            })
        }
        return result
    };

    const grazingPatternOptions = {
        labels: distinctGrazingPattern() as string[],
        chart: {
            // type: 'donut',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom'
                },

            }
        }],

    }

    const generateGrazingPatternSeries = () => {
        var methods = distinctGrazingPattern()
        var counts = new Array(methods.length).fill(0);
        adminInitialSummary?.footPrintSummaryDtos?.map(element => element.grazingPattern).forEach(element => {
            element?.forEach(element => {
                var index = methods.indexOf(element)
                if (index !== -1) counts[index] = counts[index] + 1
            })
        })

        return counts
    }

    var horseTypeCategories = () => {
        var categories = adminDetailSummary?.entireDatasetSummaries.horseTypes.map((element) => { return element.type });
        return categories;
    }

    const avgNumOfHorsesOptions = {
        labels: horseTypeCategories() as string[],
        chart: {
            // type: 'donut',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom'
                },
            }
        }],
    }

    const generateAvgNumOfHorsesSeries = () => {
        var categories = horseTypeCategories() ?? [];
        var counts = new Array(categories.length).fill(0);
        adminDetailSummary?.entireDatasetSummaries.horseTypes?.forEach(element => {
            var index = categories.indexOf(element.type)
            if (index !== -1) counts[index] = counts[index] + element.avgNum
        })
        return counts
    }

    var emissionIds = () => {
        var categories = adminDetailSummary?.partialDatasetSummaires.data.map((element, index) => { return element.emissions.footPrintId });
        return categories;
    }

    var emissionsOptions = {
        chart: {
            // type: 'bar',
            height: 550,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            categories: emissionIds(),
        },
        // legend: {
        //     position: 'right',
        //     offsetY: 40
        // },
        colors: ["#606E8C", "#898176", "#FAD201", "#BDECB6", "#9DA1AA", "#CF3476", "#1C542D", "#5D9B9B", "#A03472", "#CB2821"],
        fill: {
            opacity: 1
        }
    }

    var generateEmissionSeries = () => {
        var sources = [
            "fetiliserProduction",
            "feedAndBedding",
            "entericMethane",
            "manureManagement",
            "otherLivestock",
            "nitrogenApplication",
            "organicMaterials",
            "energyUse",
            "transportOfHorses",
            "inputsTransport"
        ];
        var fertProdCount = new Array((emissionIds() ?? []).length).fill(0);
        var feedAndBeddingCount = new Array((emissionIds() ?? []).length).fill(0);
        var enthericMethaneCount = new Array((emissionIds() ?? []).length).fill(0);
        var manureManagementCount = new Array((emissionIds() ?? []).length).fill(0);
        var otherLivestockCount = new Array((emissionIds() ?? []).length).fill(0);
        var nitrogenApplicationCount = new Array((emissionIds() ?? []).length).fill(0);
        var organicMaterialsCount = new Array((emissionIds() ?? []).length).fill(0);
        var energyUseCount = new Array((emissionIds() ?? []).length).fill(0);
        var transportOfHorsesCount = new Array((emissionIds() ?? []).length).fill(0);
        var inputsTransportCount = new Array((emissionIds() ?? []).length).fill(0);

        adminDetailSummary?.partialDatasetSummaires.data?.map(element => element.emissions).forEach(element => {
            var index = (emissionIds() ?? []).indexOf(element.footPrintId)
            if (index !== -1) {
                fertProdCount[index] = fertProdCount[index] + element.fetiliserProduction.toFixed(0);
                feedAndBeddingCount[index] = feedAndBeddingCount[index] + element.feedAndBedding.toFixed(0);
                enthericMethaneCount[index] = enthericMethaneCount[index] + element.entericMethane.toFixed(0);
                manureManagementCount[index] = manureManagementCount[index] + element.manureManagement.toFixed(0);
                otherLivestockCount[index] = otherLivestockCount[index] + element.otherLivestock.toFixed(0);
                nitrogenApplicationCount[index] = nitrogenApplicationCount[index] + element.nitrogenApplication.toFixed(0);
                organicMaterialsCount[index] = organicMaterialsCount[index] + element.organicMaterials.toFixed(0);
                energyUseCount[index] = energyUseCount[index] + element.energyUse.toFixed(0);
                transportOfHorsesCount[index] = transportOfHorsesCount[index] + element.transportOfHorses.toFixed(0);
                inputsTransportCount[index] = inputsTransportCount[index] + element.inputsTransport.toFixed(0);
            }
        })

        return [
            {
                name: sources[0],
                data: fertProdCount
            },
            {
                name: sources[1],
                data: feedAndBeddingCount
            },
            {
                name: sources[2],
                data: enthericMethaneCount
            },
            {
                name: sources[3],
                data: manureManagementCount
            },
            {
                name: sources[4],
                data: otherLivestockCount
            },
            {
                name: sources[5],
                data: nitrogenApplicationCount
            },
            {
                name: sources[6],
                data: organicMaterialsCount
            },
            {
                name: sources[7],
                data: energyUseCount
            },
            {
                name: sources[8],
                data: transportOfHorsesCount
            },
            {
                name: sources[9],
                data: inputsTransportCount
            },
        ]
    }

    var distinctLandTypes = () => {
        const result: string[] = [];
        const map = new Map();
        for (const item of adminDetailSummary?.partialDatasetSummaires.data.map(element => element.landTypes) ?? []) {
            item.landAreas.map(element => element.landType)?.forEach(element => {
                if (!map.has(element)) {
                    map.set(element, true);    // set any value to Map
                    result.push(element);
                }
            })
        }
        return result
    }

    var landTypesOptions = {
        chart: {
            // type: 'bar',
            height: 550,
            stacked: true,
            toolbar: {
                show: true
            },
            zoom: {
                enabled: true
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            categories: emissionIds(),
        },
        // legend: {
        //     position: 'right',
        //     offsetY: 40
        // },
        fill: {
            opacity: 1
        }
    }

    var generateLandTypesSeries = () => {
        var types = distinctLandTypes();
        var countMatrix: number[][] = []
        types.forEach(element => {
            countMatrix.push(new Array((emissionIds() ?? []).length).fill(0))
            return;
        }
        )

        adminDetailSummary?.partialDatasetSummaires.data?.map(element => element.landTypes).forEach(element => {
            var fpIndex = (emissionIds() ?? []).indexOf(element.footPrintId)

            element.landAreas.forEach(element => {
                var landTypeIndex = (distinctLandTypes() ?? []).indexOf(element.landType)
                if (landTypeIndex !== -1) countMatrix[landTypeIndex][fpIndex] = countMatrix[landTypeIndex][fpIndex] + Number(element.area.toFixed(2))
            })
        })

        return countMatrix.map((element, index) => {
            return {
                name: distinctLandTypes()[index],
                data: element
            }
        })
    }

    const filteredManufacturedFerts = () => {
        let result = [ ...(adminDetailSummary?.entireDatasetSummaries.manufacturedFertilisers ?? [])]
        return result.sort((a, b) => (a.co2eEmission < b.co2eEmission) ? 1 : -1).slice(0, 4)
    }

    const filteredOrganicMats = () => {
        let result = [ ...(adminDetailSummary?.entireDatasetSummaries.organicFeriliters ?? [])]
        return result.sort((a, b) => (a.co2eEmission < b.co2eEmission) ? 1 : -1).slice(0, 4)
    }

    const detailFilteringHandler = () => {
        toast.info("Filtering data...");
        dispatch(getDetailSummary({
            region,
            minimumTotalHoldingArea: Number(minArea),
            minimumNumberOfHorses: Number(minHorses),
            maximumTotalHoldingArea: Number(maxArea),
            maximumNumberOfHorses: Number(maxHorses),
            holdingSystem: system,
            startYear: Number(startYear),
            endYear: Number(endYear),
            pageNumber: currentPage
        }))
    }

    const resetFilters = () => {
        setRegion("");
        setSystem("");
        setAssessmentType("");
        setStartYear("");
        setEndYear("");
        setMinArea("");
        setMaxArea("");
        setMinHorses("");
        setMaxHorses("");
    }

    return (
        <>
            {loading ? <Loader /> : <InputContainer>
                <InputMainHeadingContainer><h1>Stud Farm Carbon Calculator</h1></InputMainHeadingContainer>

                <div><h4>Admin summary</h4></div>
                <div><p>Version: {pjson.version}</p></div>

                <SummaryStatRow>
                    <SummaryStatContainer>
                        <SummaryStat>{adminInitialSummary?.totalHoldings}</SummaryStat>
                        <SummaryStatDescription>Total holdings</SummaryStatDescription>
                    </SummaryStatContainer>
                    <SummaryStatContainer>
                        <SummaryStat>{adminInitialSummary?.avgHoldingLandArea}Ha</SummaryStat>
                        <SummaryStatDescription>Avg. Holding Land Area</SummaryStatDescription>
                    </SummaryStatContainer>
                    <SummaryStatContainer>
                        <SummaryStat>{adminInitialSummary?.avgRedDieselAnnual}L</SummaryStat>
                        <SummaryStatDescription>Avg Red Diesel Annual Usage</SummaryStatDescription>
                    </SummaryStatContainer>
                    <SummaryStatContainer>
                        <SummaryStat>{adminInitialSummary?.avgNumberOfAnimals?.toFixed(0)}</SummaryStat>
                        <SummaryStatDescription>Avg. Number of Animals On Farm</SummaryStatDescription>
                    </SummaryStatContainer>
                    <SummaryStatContainer>
                        <SummaryStat>{adminInitialSummary?.totalUsers}</SummaryStat>
                        <SummaryStatDescription>Total Users</SummaryStatDescription>
                    </SummaryStatContainer>
                </SummaryStatRow>
                <SummaryStatRow>
                    <SummaryStatContainer>
                        <SummaryStat>{adminInitialSummary?.avgDieselForHorseTransport}L</SummaryStat>
                        <SummaryStatDescription>Avg. Diesel For Horse Transport</SummaryStatDescription>
                    </SummaryStatContainer>
                    <SummaryStatContainer>
                        <SummaryStat>{adminInitialSummary?.avgCO2EForLGV}</SummaryStat>
                        <SummaryStatDescription>Avg. CO<sub>2</sub>e Emissions From LGVs</SummaryStatDescription>
                    </SummaryStatContainer>
                    <SummaryStatContainer>
                        <SummaryStat>{adminInitialSummary?.avgCO2EForHGV}</SummaryStat>
                        <SummaryStatDescription>Avg CO<sub>2</sub>e Emissions From HGVs</SummaryStatDescription>
                    </SummaryStatContainer>
                    <SummaryStatContainer>
                        <SummaryStat>{adminInitialSummary?.totalFootprints}</SummaryStat>
                        <SummaryStatDescription>Total Footprints</SummaryStatDescription>
                    </SummaryStatContainer>
                    <SummaryStatContainer>
                        <SummaryStat>{adminInitialSummary?.totalArchievedHoldings}</SummaryStat>
                        <SummaryStatDescription>Archived Holdings</SummaryStatDescription>
                    </SummaryStatContainer>
                </SummaryStatRow>

                <SummaryStatRow>
                    <SummaryGraphContainer>
                        <SummaryGraphTitle>Holding Regions</SummaryGraphTitle>
                        <Chart
                            options={holdingRegionsOptions}
                            series={generateHoldingRegionsSeries()}
                            type="bar"
                            height={350}
                        />
                    </SummaryGraphContainer>
                    <SummaryGraphContainer>
                        <SummaryGraphTitle>Holding Systems</SummaryGraphTitle>
                        <Chart
                            options={holdingSystemsOptions}
                            series={generateHoldingSystemsSeries()}
                            type="donut"
                            height={350}
                        />
                    </SummaryGraphContainer>
                </SummaryStatRow>
                <SummaryStatRow>
                    <SummaryGraphContainer>
                        <SummaryGraphTitle>Footprint Assessment Types</SummaryGraphTitle>
                        <Chart
                            options={footprintAssessmentTypesOptions}
                            series={generateAssessmentTypesSeries()}
                            type="treemap"
                            height={350}
                        />
                    </SummaryGraphContainer>
                    <SummaryGraphContainer>
                        <SummaryGraphTitle>Footprint Accounting Year End</SummaryGraphTitle>
                        <Chart
                            options={accountingPeriodEndOptions}
                            series={generateAccountingEndYearSeries()}
                            type="donut"
                            height={350}
                        />
                    </SummaryGraphContainer>
                </SummaryStatRow>
                <SummaryStatRow>
                    <SummaryGraphContainer>
                        <SummaryGraphTitle>Method of Muck Heap Removal</SummaryGraphTitle>
                        <Chart
                            options={muckMethodOptions}
                            series={generateMuckMethodSeries()}
                            type="donut"
                            height={350}
                        />
                    </SummaryGraphContainer>
                    <SummaryGraphContainer>
                        <SummaryGraphTitle>Grazing Pattern</SummaryGraphTitle>
                        <Chart
                            options={grazingPatternOptions}
                            series={generateGrazingPatternSeries()}
                            type="donut"
                            height={350}
                        />
                    </SummaryGraphContainer>
                </SummaryStatRow>

                <div className='mt-5'><h4>Detailed filtering</h4></div>

                <SummaryFilterOptionsContainer>
                    <div className='row'>
                        <div className='col-sm-6 col-md-4 col-12'>
                            <div>
                                <label>Region</label>
                                <select
                                    value={region}
                                    onChange={(e) => setRegion(e.target.value)}
                                    className='form-control'
                                >
                                    <option value="">--Please choose an option--</option>
                                    {dropdowns?.filter(element => element.theme === "RegionList").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <label>System</label>
                                <select
                                    value={system}
                                    onChange={(e) => setSystem(e.target.value)}
                                    className='form-control'
                                >
                                    <option value="">--Please choose an option--</option>
                                    {dropdowns?.filter(element => element.theme === "FarmType").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-12'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <label>Start Year</label>
                                    <input
                                        type='number'
                                        value={startYear}
                                        onChange={(e) => setStartYear(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                                <div>
                                    <label>End Year</label>
                                    <input
                                        type='number'
                                        value={endYear}
                                        onChange={(e) => setEndYear(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <label>Min Horses</label>
                                    <input
                                        type='number'
                                        value={minHorses}
                                        onChange={(e) => setMinHorses(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                                <div>
                                    <label>Max Horses</label>
                                    <input
                                        type='number'
                                        value={maxHorses}
                                        onChange={(e) => setMaxHorses(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='col-sm-6 col-md-4 col-12'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <label>Min Area (Ha)</label>
                                    <input
                                        type='number'
                                        value={minArea}
                                        onChange={(e) => setMinArea(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                                <div>
                                    <label>Max Area (Ha)</label>
                                    <input
                                        type='number'
                                        value={maxArea}
                                        onChange={(e) => setMaxArea(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div>
                                <label>Assessment Type</label>
                                <select
                                    value={assessmentType}
                                    onChange={(e) => setAssessmentType(e.target.value)}
                                    className='form-control'
                                >
                                    <option value="">--Please choose an option--</option>
                                    {dropdowns?.filter(element => element.theme === "AssessmentType").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                </select>
                            </div>

                        </div>

                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <AdminSummaryFilterButton onClick={resetFilters} >Reset Filters</AdminSummaryFilterButton>
                        <AdminSummaryFilterButton onClick={detailFilteringHandler} disabled={detailSummaryLoading}>Apply</AdminSummaryFilterButton>
                    </div>

                </SummaryFilterOptionsContainer>

                {!detailSummaryLoading && adminDetailSummary && (
                    <>
                        <SummaryStatRow>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Manufactured Fertiliser</SummaryGraphTitle>
                                <SummaryFertTable>
                                    <thead>
                                        <tr>
                                            <th>Fertiliser</th>
                                            <th>Num of Applications</th>
                                            <th>Total Emissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adminDetailSummary.entireDatasetSummaries.manufacturedFertilisers.length === 0 ? <tr><td colSpan={3}>There are no records that fit this criteria</td></tr> : (
                                            <>
                                                {filteredManufacturedFerts().map(element => {
                                                    return <tr key={element.type}>
                                                        <td>{element.type}</td>
                                                        <td>{element.count}</td>
                                                        <td>{element.co2eEmission.toFixed(2)}CO<sub>2</sub>e</td>
                                                    </tr>
                                                })}
                                            </>
                                        )}
                                    </tbody>
                                </SummaryFertTable>
                            </SummaryGraphContainer>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Organic Materials</SummaryGraphTitle>
                                <SummaryFertTable>
                                    <thead>
                                        <tr>
                                            <th>Material</th>
                                            <th>Num of Applications</th>
                                            <th>Total Emissions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adminDetailSummary.entireDatasetSummaries.organicFeriliters.length === 0 ? <tr><td colSpan={3}>There are no records that fit this criteria</td></tr> : (
                                            <>
                                                {filteredOrganicMats().map(element => {
                                                    return <tr key={element.type}>
                                                        <td>{element.type}</td>
                                                        <td>{element.count}</td>
                                                        <td>{element.co2eEmission.toFixed(2)}CO<sub>2</sub>e</td>
                                                    </tr>
                                                })}
                                            </>
                                        )}
                                    </tbody>
                                </SummaryFertTable>
                            </SummaryGraphContainer>
                        </SummaryStatRow>
                        <SummaryStatRow>
                            <SummaryGraphContainer style={{ width: "100%" }}>
                                <SummaryGraphTitle>Avg. Number Per Horse Type</SummaryGraphTitle>

                                {generateAvgNumOfHorsesSeries().length === 0 ? (<p>There are no records that fit this criteria</p>) : (
                                    <Chart
                                        options={avgNumOfHorsesOptions}
                                        series={generateAvgNumOfHorsesSeries()}
                                        type="donut"
                                        height={350}
                                    />
                                )}
                            </SummaryGraphContainer>
                        </SummaryStatRow>
                        <SummaryStatRow>
                            <SummaryGraphContainer style={{ width: "100%" }}>
                                <SummaryGraphTitle>Emissions (kgCO2e/year)</SummaryGraphTitle>
                                <Chart
                                    options={emissionsOptions}
                                    series={generateEmissionSeries()}
                                    type="bar"
                                    height={550}
                                />
                            </SummaryGraphContainer>
                        </SummaryStatRow>
                        <SummaryStatRow>
                            <SummaryGraphContainer style={{ width: "100%" }}>
                                <SummaryGraphTitle>Land Types (Ha)</SummaryGraphTitle>
                                <Chart
                                    options={landTypesOptions}
                                    series={generateLandTypesSeries()}
                                    type="bar"
                                    height={550}
                                />
                            </SummaryGraphContainer>
                        </SummaryStatRow>
                        {adminDetailSummary.partialDatasetSummaires.paginationMetaData.totalCount > adminDetailSummary.partialDatasetSummaires.paginationMetaData.currentPage && (
                            <SummaryStatRow className='d-flex justify-content-between align-items-center'>
                                <AdminSummaryPaginationButton disabled={adminDetailSummary.partialDatasetSummaires.paginationMetaData.currentPage - 1 < 1} onClick={() => {
                                    setCurrentPage(currentPage - 1);

                                }}>Previous Page</AdminSummaryPaginationButton>
                                <div>Page {adminDetailSummary.partialDatasetSummaires.paginationMetaData.currentPage} / {adminDetailSummary.partialDatasetSummaires.paginationMetaData.totalPages}</div>
                                <AdminSummaryPaginationButton disabled={adminDetailSummary.partialDatasetSummaires.paginationMetaData.currentPage + 1 > adminDetailSummary.partialDatasetSummaires.paginationMetaData.totalPages} onClick={() => {
                                    setCurrentPage(currentPage + 1);

                                }}>Next Page</AdminSummaryPaginationButton>
                            </SummaryStatRow>
                        )}
                    </>
                )}

            </InputContainer>}
        </>
    )
}

export default Summary